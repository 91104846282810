export default {
  // 首页
  aboutus: "ABOUT US",
  contactus:"CONTACT US",
  downpdf:"DOWNLOAD",
  getData:"ACQUIRING DATA",
  serviceOverview: "SERVICE OVERVIEW",
  coreDomain: "CORE COMPETENCIES",
  projectPlan: "PROJECT PLAN",
  news: "NEWS",
  LearnMore:"LEARN MORE",
  banner_text1:"CITIZEN SERVICES",
  banner_text2:"CORPORATE SERVICES",
  banner_text3:"GOVERNMENT SERVICES",
  banner_desc11:"Our comprehensive residency and relocation programs are designed to support individuals seeking to live and work abroad, offering tailored solutions to ensure a seamless and efficient transition, regardless of your destination.",
  banner_desc12:"We take pride in curating and customizing residency options that safeguard the interests and aspirations of all stakeholders, making the relocation journey smooth and aligned with your long-term goals.",
  banner_desc13:"Our commitment is to provide unparalleled service and expertise, ensuring that your international move is both effortless and rewarding.",
  banner_desc21:"The Future Citizen Bureau’s Corporate Solutions program is dedicated to providing bespoke global mobility products and services, empowering businesses to thrive in international markets.",
  banner_desc22:"We specialize in crafting tailored solutions that address the unique needs of each enterprise, enabling them to deliver enhanced value to their clients while driving greater economic and social impact. Our focus is on fostering global success through innovation, precision, and a commitment to maximizing both business performance and societal benefit.",
  banner_desc31:"The Future Citizen Bureau’s Government Solutions program is dedicated to delivering innovative and sustainable strategies that empower governments to navigate the complexities and opportunities of globalization.",
  banner_desc32:"We focus on crafting tailored solutions that drive economic growth and social productivity, fostering long-term stability and sustainable development for nations. Our commitment is to support governments in achieving their strategic objectives while enhancing overall societal well-being.",
  bahamas:"BAHAMAS",
  slovenia:"SLOVENIA",
  ireland:"IRELAND",
  vanuatu:"VANUATU",
  Citizenship:"CITIZENSHIP PROGRAMS",
  Residency:"RESIDENCY PROGRAMS",
  countryAdv:"KEY NATIONAL ADVANTAGES ",
  projectAdv:"KEY PROJECT ADVANTAGES",
  process:"APPLICATION PROCESS",
  projectStep:"PROJECT IMPLEMENTATION STEPS",
  investor:"INVESTOR CONTRIBUTIONS AND OPPORTUNITIES",
  whychoiceus:"WHY CHOOSE US?",
  usteam:"OUR TEAM",
  usservice:"OUR SERVICES",
  mission:"Mission",
  vision:"Vision",
  address:"Room 1012, Tower T1 Building C, Wangjing SOHO, Chaoyang District, Beijing,  China",
  helloDesc:"Thank you for visiting Future Citizen Bureau. We encourage all individuals passionate about globalization and sustainable development to reach out and join us in our mission to contribute to global progress and shared prosperity. We look forward to collaborating with you as we explore the boundless possibilities of the future, hand in hand.",
  messageBord:"MESSAGE BOARD",
  username:"Name",
  phone:"Phone",
  email:"Email",
  message:"Your Message",
  submit:"SUBMIT",
  pleaseLeave:"Please leave your contact information",
  pleaseCorrect:"Please provide the correct email address for us to contact you",
  submitSuccess:"SUBMIT SUCCESSFULLY",
  bottom:"2024 FUTURE CITIZEN BUREAU. All Rights Reserved.",
  newsPrev:"PREVIOUS POST",
  newsNext:"NEXT CHAPTER",
  newsReturnList:"RETURN LIST",
};

export default {
  // 首页
  aboutus: "关于我们",
  contactus:"联系我们",
  downpdf:"下载PDF",
  getData:"获取资料",
  serviceOverview: "服务概览",
  coreDomain: "核心领域",
  projectPlan: "项目计划",
  news: "新闻资讯",
  LearnMore:"查看更多",
  banner_text1:"公民服务",
  banner_text2:"企业服务",
  banner_text3:"政府服务",
  banner_desc11:"我们的公民居留移居项目旨在为希望在异国他乡生活和工作的公民提供全方位的支持和服务。",
  banner_desc12:"我们致力于让您的移居过程变得简单而高效，无论您选择哪个国家。",
  banner_desc13:"我们特别注重甄选和设计具体居留项目，以实现和保护相关方的利益和追求目标。",
  banner_desc21:"未来公民署的企业解决方案项目致力于为企业提供定制化的全球流动性产品和服务，帮助他们在全球市场中取得成功。",
  banner_desc22:"我们特别注重根据企业的具体需求设计相关产品，以便企业能够更好地为其客户服务，从而创造更多的经济价值和社会价值。",
  banner_desc31:"未来公民署的政府解决方案项目专注于为各国政府提供创新和可持续的解决方案，帮助他们应对全球化带来的挑战和机遇。",
  banner_desc32:"我们特别注重为政府量身定制解决方案，以提高经济动力和社会生产力，实现长期稳定的发展。",
  bahamas:"巴哈马",
  slovenia:"斯洛文尼亚",
  ireland:"爱尔兰",
  vanuatu:"瓦努阿图",
  Citizenship:"公民项目",
  Residency:"居留项目",
  countryAdv:"国家优势",
  projectAdv:"项目优势",
  process:"申请流程",
  projectStep:"项目实施步骤",
  investor:"投资者的贡献与机会",
  whychoiceus:"为什么选择我们？",
  usteam:"我们的团队",
  usservice:"我们的服务",
  mission:"使命",
  vision:"愿景",
  address:"北京朝阳区望京街道望京SOHO T1C座1012",
  helloDesc:"感谢来到未来公民署，我们鼓励所有对全球化和可持续发展充满热情的人士与我们联系，加入我们的行列，共同为全球社会的进步和繁荣贡献力量。我们期待与您携手，共同探索未来的无限可能。",
  messageBord:"留言板",
  username:"姓名",
  phone:"电话",
  email:"邮箱",
  message:"留言",
  submit:"提交",
  pleaseLeave:"请留下您的联系方式",
  pleaseCorrect:"请提供正确的邮箱地址，便于我们与您联系",
  submitSuccess:"提交成功",
  bottom:"© 2024 FUTURE CITIZEN BUREAU 保留所有权利",
  newsPrev:"上一篇",
  newsNext:"下一篇",
  newsReturnList:"返回列表",
};
